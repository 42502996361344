const data = [
  {
    id: 1,
    name: "Aarav Joshi",
    image: require("../Img/aarav.jpg"),
    text: "Aspire Nexus provided me with an amazing internship experience. I was able to work on real-world projects, and the team was very supportive throughout. I gained valuable skills that will definitely help me in my career!",
    star: 4,
    date: "03/08/2023",
  },
  {
    id: 2,
    name: "Sneha Patil",
    image: require("../Img/Sneha.jpg"),
    text: "My internship at Aspire Nexus was incredible! The hands-on learning experience was unlike anything I’ve had before. Highly recommended!",
    star: 5,
    date: "10/09/2023",
  },
  {
    id: 3,
    name: "Mohammad Hussain",
    image: require("../Img/hussain.jpg"),
    text: "The internship was quite good, but there were a few things that could have been better. While the projects were interesting, I felt like there could have been more guidance in certain areas. Overall, a decent experience.",
    star: 3,
    date: "11/09/2024",
  },
  {
    id: 4,
    name: "Ananya Verma",
    image:require("../Img/ananya.jpg"),
    text: "I had a wonderful time interning at Aspire Nexus. They gave me the opportunity to lead projects and contribute to meaningful work. The experience boosted my confidence and prepared me well for future job roles.",
    star: 4,
    date: "13/10/2024",
  },
];

export default data;
