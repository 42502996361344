import React from "react";
import "../../Components/Integrate.css";
import "../../Components/Style/Footer.css";
import "../../Components/Style/color.css";
import "../../Components/Style/universal.css";
import "../../Components/Navbar/Navbar.css";
import "../../Components/AboutUs/AboutUs.css";
import Footer from "../Footer/Footer";

const Aboutus = () => {
  return (
    <div id="instructor" className="about-uss font-sans text-center">
      <div className="instructor-title">
        <p className="text-bold title">
        <span>
       Get to know us</span>
        </p>
      </div>
      <div className="about-container">
      <section className="intro-section">
        {/* <h1>Get to know us</h1> */}
        <p><strong>Aspire Nexus</strong> is a platform dedicated to bridging the gap between education and the tech industry by offering high-quality internships. We believe in the power of practical, hands-on experience to shape future professionals and empower them to excel in their careers.</p>
        <p>Our internships are tailored to give you the tools, knowledge, and opportunities you need to succeed in today’s fast-paced, tech-driven world. At <strong>Aspire Nexus</strong>, we value curiosity, innovation, and growth. Our mission is to help aspiring tech enthusiasts build their dream careers.</p>
      </section>

      <section className="mission-vision-section">
        <div className="mission">
          <h2>Our Mission</h2>
          <p>We believe that learning by doing is the most effective way to master skills. Through our internship programs, we aim to provide students with real-world exposure to modern technologies, live projects, and guidance from industry professionals. Whether you’re diving into web development, design, data science, or app development, we’re here to support your journey.</p>
        </div>

        <div className="vision">
          <h2>Our Vision</h2>
          <p>Our vision is to create a future where knowledge and opportunity are acce`ssible to everyone passionate about technology. We aspire to foster an environment where learning meets creativity, innovation, and purpose. By equipping young minds with the right skills, we aim to make a lasting impact on the tech industry, empowering students to shape the future of digital transformation.</p>
        </div>
      </section>

      <section className="values-section">
        <h2>Our Values</h2>
        <p>At <strong>Aspire Nexus</strong>, we believe in collaboration, inclusivity, and the relentless pursuit of growth. We are passionate about empowering the next generation of tech leaders and ensuring that everyone, regardless of their background, has the opportunity to thrive in the industry.</p>
      </section>
    </div>
    
    </div>
  );
};

export default Aboutus;
