import React, { useState } from 'react';
import { FaReact, FaNodeJs, FaDatabase, FaLock } from 'react-icons/fa';
import { SiDjango, SiFlask } from 'react-icons/si';
import '../Services/Services.css'; // Importing the external CSS
import Footer from "../Footer/Footer";

// Popup Modal Component
const PopupModal = ({ message, isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <button className="popup-close" onClick={onClose}>×</button>
        <h2>Coming Soon!</h2>
        <p>{message}</p>
        <button className="popup-close-button" onClick={onClose}>Close </button>
      </div>
    </div>
  );
};

const Services = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');

  const handleCardClick = (service) => {
    setPopupMessage(`Stay tuned for our Services!`);
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <div className="services-container">
      <div className="services-header">
        <h2>All Your Services in <span className="highlight">One Place</span></h2>
        <p>Run anything with the most reliable technologies.</p>
      </div>
      <div className="services-grid">
        {/* Web App */}
        <div className="service-card" onClick={() => handleCardClick('Web App')}>
          <div className="icon"><FaReact /></div>
          <h3>Web App</h3>
          <ul>
            <li>Django</li>
            <li>Rails</li>
            <li>Phoenix</li>
          </ul>
        </div>

        {/* Static Sites */}
        <div className="service-card" onClick={() => handleCardClick('Static Sites')}>
          <div className="icon"><FaNodeJs /></div>
          <h3>Static Sites</h3>
          <ul>
            <li>Create React App</li>
            <li>Gatsby</li>
            <li>Hugo</li>
          </ul>
        </div>

        {/* Background Workers */}
        <div className="service-card" onClick={() => handleCardClick('Background Workers')}>
          <div className="icon"><FaNodeJs /></div>
          <h3>Background Workers</h3>
          <ul>
            <li>Kafka</li>
            <li>Celery</li>
            <li>Sidekiq</li>
          </ul>
        </div>

        {/* APIs */}
        <div className="service-card" onClick={() => handleCardClick('APIs')}>
          <div className="icon"><SiFlask /></div>
          <h3>APIs</h3>
          <ul>
            <li>Flask</li>
            <li>Express</li>
            <li>GraphQL</li>
          </ul>
        </div>

        {/* Cron Jobs */}
        <div className="service-card" onClick={() => handleCardClick('Cron Jobs')}>
          <div className="icon"><FaDatabase /></div>
          <h3>Cron Jobs</h3>
          <p>Run any command on a fixed schedule reliably and easily.</p>
        </div>

        {/* Dockerfiles */}
        <div className="service-card" onClick={() => handleCardClick('Dockerfiles')}>
          <div className="icon"><FaNodeJs /></div>
          <h3>Dockerfiles</h3>
          <p>Host virtually anything with custom Dockerfiles.</p>
        </div>

        {/* PostgreSQL */}
        <div className="service-card" onClick={() => handleCardClick('PostgreSQL')}>
          <div className="icon"><FaDatabase /></div>
          <h3>PostgreSQL</h3>
          <p>Fully managed databases with automated backups.</p>
        </div>

        {/* Private Services */}
        <div className="service-card" onClick={() => handleCardClick('Private Services')}>
          <div className="icon"><FaLock /></div>
          <h3>Private Services</h3>
          <p>Run internal services without any configuration.</p>
        </div>
      </div>

      {/* Popup Modal */}
      <PopupModal
        message={popupMessage}
        isOpen={isPopupOpen}
        onClose={closePopup}
      />
    </div>
  );
};

export default Services;
