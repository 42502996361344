import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import '../../Components/ContactUs/ContactUs.css';
import { FaGraduationCap, FaMicrophone, FaUsers, FaEnvelope, FaPhone, FaWhatsapp, FaMapMarkerAlt } from 'react-icons/fa';
import Footer from '../Footer/Footer';

export const ContactUs = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_evvp3zu', 'template_4sx3uhc', form.current, 'MpzPN2FNfOzdSllwb') // Send the public key as a string, not an object
      .then(
        (result) => {
          alert('We will contact you soon.!');
          console.log('SUCCESS!', result.text);
        },
        (error) => {
          alert('Failed to send email. Please try again.');
          console.log('FAILED...', error.text);
        },
      );
  };

  return (
    <div className="contact-page">
   <div class="contact-section">
    <div class="contact-bg">
        <div class="image"></div>
        <div class="contact-title">
            <h1>Get in Touch</h1>
            <p>Connect with us for any inquiries or collaboration opportunities</p>
        </div>
    </div>
</div>

     
      {/* Contact Details */}
     

      {/* Contact Form */}
      <div className="contact-us-container">
     
        <form ref={form} onSubmit={sendEmail} className="contact-form">
          <label htmlFor="from_name">Name</label>
          <input type="text" name="from_name" required />
          <label htmlFor="to_name">Email</label>
          <input type="email" name="to_name" required />
          <label htmlFor="message">Message</label>
          <textarea name="message" required />
          <input type="submit" value="Send your Message" />
        </form>
      </div>
      <div className="contact-container">
        <div className="contact-details">
          <div className="contact-item">
            <FaGraduationCap className="contact-icon" />
            <h3>Name</h3>
            <p>Official Name Aspire Nexus</p>
            
          </div>
          <div className="contact-item">
            <FaMicrophone className="contact-icon" />
            <h3>For Speakers</h3>
            <p>internship@aspirenexus.gmail.com</p>
          </div>
          <div className="contact-item">
            <FaUsers className="contact-icon" />
            <h3>For Collaborations</h3>
            <p>aspirenexusofficial@gmail.com</p>
          </div>
          <div className="contact-item">
            <FaEnvelope className="contact-icon" />
            <h3>For Sponsorships</h3>
            <p>support.aspirenexus@gmail.com</p>
          </div>
        </div>

        {/* Address Details */}
        <div className="address-details">
          <div className="contact-item">
            <FaMapMarkerAlt className="contact-icon" />
            <h3>Address</h3>
            <p>Maharashtra 411041</p>
          </div>
          <div className="contact-item">
            <FaPhone className="contact-icon" />
            <h3>Phone</h3>
            <p>+91 91234 56789</p>
          </div>
          <div className="contact-item">
            <FaWhatsapp className="contact-icon" />
            <h3>Whatsapp</h3>
            <p>+91 91234 56789</p>
          </div>
          <div className="contact-item">
            <FaEnvelope className="contact-icon" />
            <h3>Queries</h3>
            <p>info.aspirenexus@gmail.com</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
