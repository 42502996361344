import React from 'react';
import './Review.css'; // Import CSS

const testimonials = [
  {
    name: "Torie Rusche",
    reviewDate: "11 days ago",
    review: "This is a nice site. Good Job",
    rating: 5,
  },
  {
    name: "Morgan Sizemore",
    reviewDate: "12 days ago",
    review: "I completed a 1-month remote internship. Great experience and easy process for students seeking practical exposure!",
    rating: 5,
  },
  {
    name: "Cara Lundrigan",
    reviewDate: "1 month ago",
    review: "Windmill Strategy did an amazing job working with us to rebrand and launch our new website. Their processes and best practices helped us determine our core identity...",
    rating: 5,
  },
  {
    name: "Mark Recio",
    reviewDate: "2 months ago",
    review: "Professionals to the core! The team at Windmill works diligently to translate your needs into a comprehensive solution for your digital needs. I cannot recommend this team more...",
    rating: 5,
  },
  {
    name: "Ashleigh Ellis",
    reviewDate: "2 months ago",
    review: "We’ve been working with Windmill Strategy to improve our digital performance, specifically within SEO and PPC, and we’ve been very impressed with what they’ve accomplished...",
    rating: 5,
  },
  {
    name: "David Wiegand",
    reviewDate: "3 months ago",
    review: "We initially chose Windmill because of their depth of experience in helping industrial and manufacturing clients through similar website and marketing projects...",
    rating: 5,
  }, 
];

const TestimonialCard = ({ name, reviewDate, review, rating }) => (
  <div className="testimonial-card">
    <div className="client-photo">
      <img src="https://via.placeholder.com/100" alt={`${name}'s profile`} /> {/* Placeholder for photo */}
    </div>
    <div className="client-review">
      <h4>{name}</h4>
      <p className="review-date">{reviewDate}</p>
      <p>{review}</p>
      <div className="stars">
        {/* Create blank spaces for stars based on rating */}
        {[...Array(rating)].map((_, index) => (
          <div key={index} className="star-placeholder"></div> // Empty div for star space
        ))}
      </div>
    </div>
  </div>
);

const Testimonials = () => (
  <section className="testimonials">
    <h2>What clients are saying about us...</h2>
    <div className="testimonials-container">
      {testimonials.map((testimonial, index) => (
        <TestimonialCard key={index} {...testimonial} />
      ))}
    </div>
  </section>
);

export default Testimonials;
