import React from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom'; // Added BrowserRouter
import './App.css';

import Home from './Components/Home/Home';
import AboutUs from './Components/AboutUs/AboutUs';
import Navbar from './Components/Navbar/Navbar'; // Ensure this is correct
import ContactUs from './Components/ContactUs/ContactUs';
import Services from './Components/Services/Services';
import Review from './Components/Review/Review';
import Footer from './Components/Footer/Footer';




function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/ContactUs' element={<ContactUs />} /> 
          <Route path='/AboutUs' element={<AboutUs />} /> 
          <Route path='/Services' element={<Services />} /> 
          <Route path='/Review' element={<Review />} />

        </Routes>
        <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;
