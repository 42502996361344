import React from "react";
import { Link, NavLink } from "react-router-dom"; 
import log from '../../Img/mainlogo1.png';
import "./Navbar.css";
import "../../Components/Integrate.css";
import "../../Components/Style/Footer.css";
import "../../Components/Style/color.css";
import "../../Components/Style/universal.css";
import "../../Components/AboutUs/AboutUs.css";

const Navbar = () => {
  return (
    <header>
      <nav>
        <div className="nav-bar">
          {/* Logo Section */}
          <ul className="logo">
            <li>
              <a href="#">
                <img id="topLogo" src={log} alt="Aspirenexus Main Logo" />
              </a>
            </li>
          </ul>

          {/* Menu Toggle Checkbox */}
          <input type="checkbox" id="check" />

          {/* Main Menu */}
          <ul className="menu font-sans">
            <li>
              <NavLink
                className="text-white text-center"
                to="/"
                activeClassName="active"
              >
                Home
              </NavLink>
            </li>

            <li>
              <NavLink
                className="text-white text-center"
                to="/ContactUs"
                activeClassName="active"
              >
                Contact Us
              </NavLink>
            </li>

            <li>
              <NavLink
                className="text-white text-center"
                to="/AboutUs"
                activeClassName="active"
              >
                About Us
              </NavLink>
            </li>
            <li>
              <NavLink
                className="text-white text-center"
                to="/Services"
                activeClassName="active"
              >
                Services
              </NavLink>
            </li>

            <li>
              <a href="https://docs.google.com/forms/d/e/1FAIpQLSfYd1EoXnlVXszDcuxf-sto3UfRbDFs5hg9hvK2V623I7v4_A/viewform?usp=sf_link" target="blank"  >
                <button className="btn-design text-bold"> Easy Apply </button>
              </a>
            </li>
          </ul>

          {/* Close Menu Button */}
          <label htmlFor="check" className="close-menu">
            <i className="text-white fas fa-times"></i>
          </label>

          {/* Open Menu Button (Hamburger icon with 3 lines) */}
          <label htmlFor="check" className="open-menu">
            <span className="menu-line"></span>
            <span className="menu-line"></span>
            <span className="menu-line"></span>
          </label>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
