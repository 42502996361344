import React, { useEffect, useRef, useState } from "react";
import boy from "../../Img/headerImage.webp";
import "../../Components/Integrate.css";
import "../../Components/Style/color.css";
import "../../Components/Style/universal.css";
import "../../Components/Navbar/Navbar.css";
import detail from "../../Adv_sec/data_science.png";
import ai from "../../Adv_sec/Ai.jpg";
import adroid from "../../Adv_sec/android_dev.png";
import web from "../../Img/practiceProblems.webp";
import industry from "../../Adv_sec/python.jpg";
import lifetime from "../../Adv_sec/j3.jpg";
import double from "../../Img/doubtSupport.webp";
import certificate from "../../Adv_sec/Top-UX-Designers.jpg";
import circle from "../../Img/circle-dp.webp";
import youtube from "../../Img/youtube.webp";
import instagram from "../../Img/instagram.webp";
import linkedin from "../../Img/linkedin.webp";
import logo_light from "../../Img/logo.webp";
import gitdark from "../../Img/github-dark.webp";
import gitlight from "../../Img/github-light.webp";
import logo_dark from "../../Img/logo_dark.webp";
import Typed from "typed.js";
import img1 from "../../Adv_sec/web2.jpg";
import { NavLink } from "react-router-dom";
import ContactUs from "../../Components/ContactUs/ContactUs";
import log from "../../Img/IMG_20241002_150115.png";
import video from "../../Video/video.mp4";
import ReactPlayer from "react-player";
import div1 from "../../Img/div2.jpg";
import logo from "../../Img/mainlogo1.png";
import { Link } from "react-router-dom";
import Footer from "../Footer/Footer";
import Social from "../Socialmedia/Social";
import Review from "../ReviewCard/Review";
import data from "../../data/data"

const Home = () => {
  const [isDarkMode, setIsDarkMode] = useState(true); // Dark mode state

  // Refs for the elements we want to control
  const count1Ref = useRef(null);
  const count2Ref = useRef(null);
  const checkboxRef = useRef(null); // Define checkboxRef here
  const gitLogoRef = useRef(null);
  const footerLogoRef = useRef(null);
  const topLogoRef = useRef(null);

  // Function for counting animation
  const countAnimation = (targetNumber, ref) => {
    let currentNumber = 0;
    const increment = Math.ceil(targetNumber / 250);
    const intervalTime = 20;

    const interval = setInterval(() => {
      currentNumber += increment;
      if (currentNumber >= targetNumber) {
        clearInterval(interval);
        currentNumber = targetNumber;
      }
      if (ref.current) {
        ref.current.textContent = currentNumber.toLocaleString() + "+";
      }
    }, intervalTime);
  };

  // Function to handle theme change
  const handleCheckboxChange = () => {
    const body = document.body;
    const gitLogo = gitLogoRef.current;
    const footerLogo = footerLogoRef.current;
    const topLogo = topLogoRef.current;

    if (isDarkMode) {
      body.classList.add("dark");
      body.classList.remove("light");
      if (gitLogo) gitLogo.src = { gitdark };
      if (footerLogo) footerLogo.src = { logo_light };
      if (topLogo) topLogo.src = { logo_light };
    } else {
      body.classList.remove("dark");
      body.classList.add("light");
      if (gitLogo) gitLogo.src = { gitlight };
      if (footerLogo) footerLogo.src = { logo_dark };
      if (topLogo) topLogo.src = { gitdark };
    }
  };

  // useEffect to run the animations and checkbox handling when the component mounts
  useEffect(() => {
    countAnimation(100, count1Ref); // Start count animation for the first text
    countAnimation(2, count2Ref); // Start count animation for the second text

    handleCheckboxChange(); // Run theme change initially
  }, [isDarkMode]); // Dependency array to trigger re-run on theme change

  return (
    <div className="home-root">
      {/* First Section */}
      <div className="first-section">
        <div className="headerText font-sans">
          <h2 className="section-one-title text-bold">
            Coding Career <br />
            <span>
              Learn, Apply, and Thrive in Your Chosen Field!
              <span className="typee"></span>
            </span>
          </h2>
          <h3 className="text-white">
            Unlock Your Coding Potential:Career-building Internships , Learning
            Experiences Expert Instructors, Real Projects & Assignments !
          </h3>
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSfYd1EoXnlVXszDcuxf-sto3UfRbDFs5hg9hvK2V623I7v4_A/viewform?usp=sf_link"
            target="_blank"
            className="text-bold"
          >
            Apply Now
          </a>
        </div>
        <div className="headerImage-div">
          <img
            src="https://assets.zyrosite.com/Aq20eV79zLfpXV6b/web-development-2-YX4ZEeEEnwI13r0K.gif"
            srcSet="
      https://assets.zyrosite.com/Aq20eV79zLfpXV6b/web-development-2-YX4ZEeEEnwI13r0K.gif 328w,
      https://assets.zyrosite.com/Aq20eV79zLfpXV6b/web-development-2-YX4ZEeEEnwI13r0K.gif 656w,
      https://assets.zyrosite.com/Aq20eV79zLfpXV6b/web-development-2-YX4ZEeEEnwI13r0K.gif 861w,
      https://assets.zyrosite.com/Aq20eV79zLfpXV6b/web-development-2-YX4ZEeEEnwI13r0K.gif 984w,
      https://assets.zyrosite.com/Aq20eV79zLfpXV6b/web-development-2-YX4ZEeEEnwI13r0K.gif 379w,
      https://assets.zyrosite.com/Aq20eV79zLfpXV6b/web-development-2-YX4ZEeEEnwI13r0K.gif 758w
    "
            sizes="(min-width: 920px) 379px, calc(100vw - 0px)"
            height="340"
            width="679"
            loading="lazy"
            alt="A Boy Working on Laptop"
            className="headerImage"
          />
        </div>
      </div>
      <div className="div2">
        <img src={div1} alt="" />
        <p>
          <span>Boost Your Experience</span> At Aspire Nexus, our internship
          model empowers aspiring professionals with real-world project
          experience and growth opportunities. As an MSME Government Certified
          organization, we ensure quality mentorship and training, preparing you
          for today’s competitive tech landscape.
        </p>
      </div>

      <div className="line-div"></div>

      {/* Second Section */}
      <div className="second-section">
        <div className="journey-title text-bold text-center">
          <p className="font-sans">Our journey so far</p>
        </div>
        <div className="journey-list">
          <div className="journey-items font-sans text-center">
            <h4>4.2⭐️</h4>
            <p>Course ratings</p>
          </div>
          <div className="border-right"></div>
          <div className="journey-items font-sans text-center">
            <h4 ref={count1Ref}>35,000</h4>
            <p>Enrollments</p>
          </div>
          <div className="border-right"></div>
          <div className="journey-items font-sans text-center">
            <h4 ref={count2Ref}>2</h4>
            <p>Countries</p>
          </div>
        </div>
      </div>

      {/* Line Divider */}
      <div className="line-div"></div>

      {/* Third Section */}
      <div id="courses" className="third-section font-sans">
        <div>
          <h1 className="third-section-title text-center title">
            We offer exclusive services for{" "}
            <span>
              <b>our clients.</b>
            </span>
          </h1>
        </div>
        <div>
          <a href="#">
            <div className="card">
              <div>
                <div>
                  <img
                    className="course-thumbnail image__image"
                    alt="Course Thumbnail"
                    src="https://assets.zyrosite.com/Aq20eV79zLfpXV6b/bb375cdd655184ca2715ac5059e73651-YX4ZEeZEvbhrMMZa.gif"
                    srcSet="https://assets.zyrosite.com/Aq20eV79zLfpXV6b/bb375cdd655184ca2715ac5059e73651-YX4ZEeZEvbhrMMZa.gif 328w,
            https://assets.zyrosite.com/Aq20eV79zLfpXV6b/bb375cdd655184ca2715ac5059e73651-YX4ZEeZEvbhrMMZa.gif 656w,
            https://assets.zyrosite.com/Aq20eV79zLfpXV6b/bb375cdd655184ca2715ac5059e73651-YX4ZEeZEvbhrMMZa.gif 861w,
            https://assets.zyrosite.com/Aq20eV79zLfpXV6b/bb375cdd655184ca2715ac5059e73651-YX4ZEeZEvbhrMMZa.gif 984w,
            https://assets.zyrosite.com/Aq20eV79zLfpXV6b/bb375cdd655184ca2715ac5059e73651-YX4ZEeZEvbhrMMZa.gif 379w,
            https://assets.zyrosite.com/Aq20eV79zLfpXV6b/bb375cdd655184ca2715ac5059e73651-YX4ZEeZEvbhrMMZa.gif 758w"
                    sizes="(min-width: 920px) 379px, calc(100vw - 0px)"
                    height="310"
                    width="379"
                    loading="lazy"
                    data-v-47c05b4e=""
                    data-qa="builder-gridelement-gridimage"
                  />
                </div>

                <div className="cont-us">
                  <div>
                    <p className="card-level text-bold">
                      BEGINNER TO ADVANCED 🔥🔥🔥
                    </p>
                    <h3 className="course-title">
                      We design and develop websites and web-based applications.
                    </h3>
                  </div>
                  <NavLink
                    className="discount-percent"
                    to="/ContactUs"
                    activeClassName="active"
                  >
                    Contact Us
                  </NavLink>

                  <a />
                </div>
              </div>
            </div>

            <div class="advantage-section bg-dark">
              <div class="advantage-sub-section">
                <div class="whycm-container">
                  <div>
                    <h1 class="advantage-title title font-sans margin-0">
                      INTERNSHIP POSITIONS 🎓
                    </h1>
                    <p class="advantage-desc font-poppin margin-0">
                      Explore specialized internship opportunities to gain
                      hands-on experience in your field and advance your career.
                    </p>
                  </div>

                  <div id="adv-sec" class="adv-section">
                    <div className="adv-card">
                      <a
                        href="https://docs.google.com/forms/d/e/1FAIpQLSfYd1EoXnlVXszDcuxf-sto3UfRbDFs5hg9hvK2V623I7v4_A/viewform?usp=sf_link"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={img1} alt="Pocket-Friendly Courses" />
                        <span className="adv-desc margin-0">Web Designing</span>
                      </a>
                    </div>

                    <div className="adv-card">
                      <a
                        href="https://docs.google.com/forms/d/e/1FAIpQLSfYd1EoXnlVXszDcuxf-sto3UfRbDFs5hg9hvK2V623I7v4_A/viewform?usp=sf_link"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={web} alt="Web Development" />
                        <span className="adv-desc margin-0">
                          Web Development
                        </span>
                      </a>
                    </div>

                    <div1 class="adv-card">
                      <a
                        href="https://docs.google.com/forms/d/e/1FAIpQLSfYd1EoXnlVXszDcuxf-sto3UfRbDFs5hg9hvK2V623I7v4_A/viewform?usp=sf_link"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={adroid} alt="" />
                        <span class="adv-desc margin-0">
                          Android Development
                        </span>
                      </a>
                    </div1>

                    <div class="adv-card">
                      <a
                        href="https://docs.google.com/forms/d/e/1FAIpQLSfYd1EoXnlVXszDcuxf-sto3UfRbDFs5hg9hvK2V623I7v4_A/viewform?usp=sf_link"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={detail} alt="" />
                        <span class="adv-desc margin-0">Data Science</span>
                      </a>
                    </div>

                    <div class="adv-card">
                      <a
                        href="https://docs.google.com/forms/d/e/1FAIpQLSfYd1EoXnlVXszDcuxf-sto3UfRbDFs5hg9hvK2V623I7v4_A/viewform?usp=sf_link"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={industry} alt="" />
                        <span class="adv-desc margin-0">
                          Python Developement
                        </span>
                      </a>
                    </div>

                    <div class="adv-card">
                      <a
                        href="https://docs.google.com/forms/d/e/1FAIpQLSfYd1EoXnlVXszDcuxf-sto3UfRbDFs5hg9hvK2V623I7v4_A/viewform?usp=sf_link"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={lifetime} alt="" />
                        <span class="adv-desc margin-0">Java Developement</span>
                      </a>
                    </div>

                    <div class="adv-card">
                      <a
                        href="https://docs.google.com/forms/d/e/1FAIpQLSfYd1EoXnlVXszDcuxf-sto3UfRbDFs5hg9hvK2V623I7v4_A/viewform?usp=sf_link"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={ai} alt="" />
                        <span class="adv-desc margin-0">
                          Artificial intelligence
                        </span>
                      </a>
                    </div>

                    <div class="adv-card">
                      <a
                        href="https://docs.google.com/forms/d/e/1FAIpQLSfYd1EoXnlVXszDcuxf-sto3UfRbDFs5hg9hvK2V623I7v4_A/viewform?usp=sf_link"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={certificate} alt="" />
                        <span class="adv-desc margin-0">UI/UX design</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="instructor" class="about-us font-sans text-center">
              <div class="instructor-title">
                <p class="text-bold title">
                  Connecting Talent - Internships Shaping Future Innovators.
                </p>
                <Link to="/AboutUs">
                  <button className="button-61 text-bold text-center">
                    Know More
                  </button>
                </Link>
              </div>
              <div class="line-long"></div>
              <div class="instructor-content">
                <ReactPlayer
                  controls={true}
                  url={video}
                  alt="video"
                  width="auto      "
                  height="auto"
                />
              </div>
              <div class="description-div">
                <p class="description">
                  Immerse yourself in an Aspire Nexus internship: a gateway to
                  real-world tech innovation and skill development. Work on
                  cutting-edge projects, guided by experienced professionals, in
                  a creative environment designed to fast-track your career.
                  Aspire Nexus is where your tech dreams turn into impactful
                  achievements. Gain hands-on experience, boost your resume, and
                  build the skills that employers are looking for. Join Aspire
                  Nexus and make your mark in the tech industry today.
                </p>
              </div>
            </div>

            <div class="line-div"></div>

            <div className="internship-container">
              <h1 className="internship-title">ADVANCE INTERNSHIP PROGRAM</h1>
              <div className="internship-cards">
                <div className="internship-card">
                  <img
                    alt=""
                    src="https://assets.zyrosite.com/Aq20eV79zLfpXV6b/1_zy_blfanotobs0g3qd3fda-Yg2lMpkLwlIKO8Nl.gif"
                    srcset="https://assets.zyrosite.com/Aq20eV79zLfpXV6b/1_zy_blfanotobs0g3qd3fda-Yg2lMpkLwlIKO8Nl.gif 328w,
             https://assets.zyrosite.com/Aq20eV79zLfpXV6b/1_zy_blfanotobs0g3qd3fda-Yg2lMpkLwlIKO8Nl.gif 656w,
             https://assets.zyrosite.com/Aq20eV79zLfpXV6b/1_zy_blfanotobs0g3qd3fda-Yg2lMpkLwlIKO8Nl.gif 861w,
             https://assets.zyrosite.com/Aq20eV79zLfpXV6b/1_zy_blfanotobs0g3qd3fda-Yg2lMpkLwlIKO8Nl.gif 984w,
             https://assets.zyrosite.com/Aq20eV79zLfpXV6b/1_zy_blfanotobs0g3qd3fda-Yg2lMpkLwlIKO8Nl.gif 224w,
             https://assets.zyrosite.com/Aq20eV79zLfpXV6b/1_zy_blfanotobs0g3qd3fda-Yg2lMpkLwlIKO8Nl.gif 448w"
                    sizes="(min-width: 920px) 224px, calc(100vw - 0px)"
                    height="183"
                    width="224"
                    loading="lazy"
                    class="image__image"
                    data-v-47c05b4e=""
                    data-qa="builder-gridelement-gridimage"
                  />

                  <h2>Flutter Developer</h2>
                  <p>
                    As a Flutter Developer intern, you will be involved in
                    developing cross-platform mobile applications using Flutter,
                    a UI toolkit from Google.
                  </p>
                  <a
                    href="https://docs.google.com/forms/d/e/1FAIpQLSdAYAYd15kDHX7P8vKYfUXtN2Yl5vQplrBtgR_i6pEYjs4Wrw/viewform?usp=sf_link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button className="apply-btn">Apply</button>
                  </a>
                </div>
                <div className="internship-card">
                  <img
                    alt=""
                    src="https://assets.zyrosite.com/Aq20eV79zLfpXV6b/giphy-mjE2epZnyMSVlz6M.gif"
                    srcset="https://assets.zyrosite.com/Aq20eV79zLfpXV6b/giphy-mjE2epZnyMSVlz6M.gif 328w,
             https://assets.zyrosite.com/Aq20eV79zLfpXV6b/giphy-mjE2epZnyMSVlz6M.gif 656w,
             https://assets.zyrosite.com/Aq20eV79zLfpXV6b/giphy-mjE2epZnyMSVlz6M.gif 861w,
             https://assets.zyrosite.com/Aq20eV79zLfpXV6b/giphy-mjE2epZnyMSVlz6M.gif 984w,
             https://assets.zyrosite.com/Aq20eV79zLfpXV6b/giphy-mjE2epZnyMSVlz6M.gif 216w,
             https://assets.zyrosite.com/Aq20eV79zLfpXV6b/giphy-mjE2epZnyMSVlz6M.gif 432w"
                    sizes="(min-width: 920px) 216px, calc(100vw - 0px)"
                    height="200"
                    width="216"
                    loading="lazy"
                    class="image__image"
                    data-v-47c05b4e=""
                    data-qa="builder-gridelement-gridimage"
                  />

                  <h2>ReactJS Developer</h2>
                  <p>
                    As a ReactJS Developer intern, you will work on building and
                    maintaining web applications using the React JavaScript
                    library.
                  </p>
                  <a
                    href="https://docs.google.com/forms/d/e/1FAIpQLSdAYAYd15kDHX7P8vKYfUXtN2Yl5vQplrBtgR_i6pEYjs4Wrw/viewform?usp=sf_link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button className="apply-btn">Apply</button>
                  </a>
                </div>
                <div className="internship-card">
                  <img
                    alt=""
                    src="https://assets.zyrosite.com/Aq20eV79zLfpXV6b/l26hxkgwri-YX4P7JNK1qsD4M57.gif"
                    srcset="https://assets.zyrosite.com/Aq20eV79zLfpXV6b/l26hxkgwri-YX4P7JNK1qsD4M57.gif 328w,
    https://assets.zyrosite.com/Aq20eV79zLfpXV6b/l26hxkgwri-YX4P7JNK1qsD4M57.gif 656w,
    https://assets.zyrosite.com/Aq20eV79zLfpXV6b/l26hxkgwri-YX4P7JNK1qsD4M57.gif 861w,
    https://assets.zyrosite.com/Aq20eV79zLfpXV6b/l26hxkgwri-YX4P7JNK1qsD4M57.gif 984w,
    https://assets.zyrosite.com/Aq20eV79zLfpXV6b/l26hxkgwri-YX4P7JNK1qsD4M57.gif 242w,
    https://assets.zyrosite.com/Aq20eV79zLfpXV6b/l26hxkgwri-YX4P7JNK1qsD4M57.gif 484w"
                    sizes="(min-width: 920px) 242px, calc(100vw - 0px)"
                    height="190"
                    width="242"
                    loading="lazy"
                    className="image__image"
                    data-v-47c05b4e=""
                    data-qa="builder-gridelement-gridimage"
                  />
                  <h2>JavaScript Developer</h2>
                  <p>
                    As a JavaScript Developer intern, you will contribute to the
                    development of interactive web applications using
                    JavaScript.
                  </p>
                  <a
                    href="https://docs.google.com/forms/d/e/1FAIpQLSdAYAYd15kDHX7P8vKYfUXtN2Yl5vQplrBtgR_i6pEYjs4Wrw/viewform?usp=sf_link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button className="apply-btn">Apply</button>
                  </a>
                </div>
              </div>
            </div>

            <div className="line-div"></div>

            <h1 className="reviews-title">REVIEWS</h1>

            <div className="review-sec">
              {data.map((review) =>
                review ? <Review key={review.id} data={review} /> : null
              )}
            </div>

            <Social /> 
          </a>
        </div>
      </div>

      {/* Line Divider */}
      <div className="line-div"></div>
    </div>
  );
};

export default Home;
