import React from "react";
import "../../Components/Integrate.css";

// Import the necessary React icons
import {
  FaYoutube,
  FaInstagram,
  FaLinkedin,
  FaGithub,
  FaFacebook,
} from "react-icons/fa";
import "../../Components/Socialmedia/Social.css";

const Social = () => {
  return (
    <div id="social" className="social-section bg-dark-light">
      <h1>Join our Coding Family</h1>
      <p>
        If you would like to keep up on the latest posts and courses, come by
        and connect with us on the following links.
      </p>
      <div className="social-card-section">
        <a
          href="https://www.youtube.com/channel/UCbPez_DmIiGD_rGburvh4Ow"
          className="social-card"
          target="_blank"
          style={{ textDecoration: "none" }}
        >
          <div className="social-icon">
            <FaYoutube size={160} color="#be2048" />
          </div>
          <div className="social-text">
            <p>YouTube</p>
          </div>
        </a>

        <a
          href="https://www.instagram.com/aspire_nexus_internship/"
          className="social-card"
          target="_blank"
          style={{ textDecoration: "none" }}
        >
          <div className="social-icon">
            <FaInstagram size={160} color="#be2048" />
          </div>
          <div className="social-text">
            <p>Instagram</p>
          </div>
        </a>

        <a
          href="https://www.linkedin.com/company/aspire-nexus_internship/about/?viewAsMember=true"
          className="social-card"
          target="_blank"
          style={{ textDecoration: "none" }}
        >
          <div className="social-icon">
            <FaLinkedin size={160} color="#be2048" />
          </div>
          <div className="social-text">
            <p>LinkedIn</p>
          </div>
        </a>

        <a
          href="https://github.com/AspireNexusTech"
          className="social-card"
          target="_blank"
          style={{ textDecoration: "none" }}
        >
          <div className="social-icon">
            <FaGithub size={160} color="#be2048" />
          </div>
          <div className="social-text">
            <p>Github</p>
          </div>
        </a>
      </div>

      {/* Add the sentence from the image */}
      <div className="text-center"></div>
    </div>
  );
};

export default Social;
